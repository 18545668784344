import { createGetApi, createPostApi } from '@friday/async'


export default class Apis {

    private manage_config = '/admin'

    private review_config = '/review'

    private admin_config = '/admin'
	
	// 管理员登录
	public login = createPostApi({ url: `/admin/login`})


    // 话题管理列表
    public topicList = createGetApi<any>({url: `/admin/topic/list`})
    // 话题审核
    public topicAudit= createPostApi<any>({url: `/admin/topic/audit`})

    // 内容管理
    public contentList = createGetApi<any>({url: `/admin/content/list`})
    // 内容审核
    public contentAudit= createPostApi<any>({url: `/admin/content/audit`})
     // 用户信息
    public fetchUserInfo = createGetApi<any>({ url: `${this.manage_config}/user/info`})

    // 用户管理
    public userList = createGetApi<any>({url: `/admin/user/list`})

  
}

