import React, { Suspense } from 'react' 
import { Route, useScrollTop, useRouteMatch } from '@friday/router'

import BaseLayout from './BaseLayout'
import Topic from '../Topic/index'
import Content from '../Content/index'
import User from '../user/index'


const PrimaryRoutes = () => {
    const match = useRouteMatch()
    useScrollTop()
    return (
        <BaseLayout>
            <Route path={`${match.path}/topic`} exact component={Topic} />
            <Route path={`${match.path}/content`} exact component={Content} />
            <Route path={`${match.path}/user`} exact component={User} />
        </BaseLayout>
    )
}

export default PrimaryRoutes