

export const protocol = document.location.protocol
export const upstreams = {
	development: {
		OPEN_API_URL: 'cms.dev.pinkma.com',
	},
	production: {
		OPEN_API_URL: 'cms.pinkma.com',
	}

}

export enum BaseUrl {
	OPEN_API_URL = 'api',
}
