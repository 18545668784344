import React from "react";
import ImgPreview from 'src/components/ImgPreview'


export const statusMap = {
    0: '未知',
    1: "正常",
    2: "禁言",
    3: "禁用"
}

const useColumns = (drawerProps) => {

    return React.useMemo(() => {
        const columns = [
        {
            title: '昵称',
            dataIndex: 'nickname'
        }, {
            title: '头像',
            dataIndex: 'avatar',
            render: (txt) => {
                return (
                    <ImgPreview 
                        url={txt}
                    />
                )
            }
        }, {
            title: '手机号',
            dataIndex: 'phone'
        },{
            title: '类型',
            dataIndex: 'admin',
            render: (txt) => {
                return txt == 1 ? '管理员': '用户'
            }
        }, {
            title: '状态',
            dataIndex: 'status',
            render: ( text) => statusMap[text]
        }]
    
        return columns
    
    }, [])
} 

export default useColumns