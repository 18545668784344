import React  from "react";
import {PageHeader, Card, Space, Input, Button, Table, Select} from 'antd'
import useColumns from './useColumns'
import { useDrawer } from '../../../hooks'
import { useApiSelector } from 'src/hooks'
import { useRequest } from '@friday/async'
import { useImmer }  from '@friday/immer'
import { get } from 'lodash'
const Index = () => { 

    const apis = useApiSelector()

    const [state, setState] = useImmer({
        key: '',
        state: '',
    })

    const { responseJson, isValidating, revalidate, pagination} = useRequest(apis.admin.userList({...state}), {paginated: true})

    const list = get(responseJson, 'data.list')

    const drawerProps = useDrawer(revalidate)

    const columns = useColumns(drawerProps)

    return (
        <div>
            <PageHeader 
                title='用户管理'
                ghost={false}
                className='mg-b-10'
            />
            <Card hoverable>
                <div className='clearfix mg-b-20'>
                    <Space>
                        <span>用户状态</span>
                        <Select style={{width: '180px'}}  value={state.state} allowClear placeholder='请选择用户状态' onChange={(e) => setState(dart => { dart.state = e as any})} >
                            <Select.Option value={1}>正常</Select.Option>
                            <Select.Option value={2}>禁言</Select.Option>
                            <Select.Option value={3}>禁用</Select.Option>
                        </Select>
                        <Input.Search 
                            style={{width: '180px'}} 
                            placeholder="请输入搜索内容" 
                            onChange={(e) => setState(dart => { dart.key = e.target.value })} 
                        />
                    </Space>
                </div>
                <Table 
                    columns={columns as any}
                    dataSource={list}
                    loading={isValidating}
                    size={'middle'}
                    scroll={{
                        x: 'max-content'
                    }}
                    rowKey={'id'}
                    pagination={pagination as any}
                />
            </Card>
        </div>
    )
}

export default Index