import React from 'react'
import { Layout, Menu, Avatar, Dropdown } from 'antd'
import { FileOutlined, FileDoneOutlined, LogoutOutlined } from '@ant-design/icons'
import { Link, useLocation} from '@friday/router'
import useUserInfo from 'src/hooks/useUserInfo'
import menuLogo from '../../home/images/menu_logo.png'

const { Header, Content, Footer, Sider } = Layout

import './index.less'


const Index = ({children}) => {

    const { userInfo } = useUserInfo()

    const { type } = userInfo as any

    const { pathname } = useLocation()

    const loginOut = () => {
        localStorage.removeItem('token')
        window.location.href = '/admin/login'
    }

    const menu = (
        <Menu>
           <Menu.Item onClick={loginOut}>
            退出登录
            </Menu.Item>
        </Menu>
    )

    const getkeys = () => {
        if (pathname.indexOf('topic') > -1) return '1'
        if (pathname.indexOf('content') > -1) return '2'
         if (pathname.indexOf('user') > -1) return '2'
        return '5'
    }

    return (
        <Layout>
            <Sider
                breakpoint="lg"
                collapsedWidth="0"
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >
                <div className="m-logo" >
                    <h3 style={{color: '#fff'}}>粉马后台管理</h3>
                    {/* <img src={menuLogo} /> */}
                </div>
                <Menu theme="dark" mode="inline" defaultSelectedKeys={[getkeys()]}  defaultOpenKeys={['2']}>
                    <Menu.Item key="1" icon={<FileOutlined  />} >
                        <Link to='/admin/topic'>
                         话题管理
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="2" icon={<FileOutlined   />} >
                        <Link to='/admin/content'>
                         内容管理
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="3" icon={<FileOutlined   />} >
                        <Link to='/admin/user'>
                         用户管理
                        </Link>
                    </Menu.Item>
                   
                </Menu>
            </Sider>
            <Layout style={{minHeight: '100vh'}} className='sider-main'>
                <Header className="site-layout-sub-header-background"  >
                    <div className='clearfix'>
                        <div className='fr m-r-10'>
                            <Dropdown overlay={menu}>
                                <Avatar src="https://xsgames.co/randomusers/avatar.php?g=pixel&key=1" />
                            </Dropdown>
                        </div>
                    </div>
                </Header>
                <Content style={{ margin: '16px 16px 0', minWidth: 328 }} >
                    <div className="site-layout-background" style={{ minHeight: 360 }}>
                        {children}
                    </div>
                </Content>
                {/* <Footer style={{ textAlign: 'center' }}>洪合杯 ©2021 Created by front </Footer> */}
            </Layout>
        </Layout>)
}

export default Index